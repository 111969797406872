import * as React from "react"
import { Link } from "gatsby"
import * as aboutStyles from "./about.module.css"
import Container from "../components/container"
import ContactLink from "../components/contactlink"
import Copyright from "../components/copyright"
import Footer from "../components/footer"
import Navbar from "../components/navbar"

import img_1 from "../images/pemalex_img_malharia_v.jpg"
import img_3 from "../images/pemalex_logo_clientes_liebe.png"
import img_4 from "../images/pemalex_logo_clientes_borogodo.png"
import img_5 from "../images/pemalex_logo_clientes_dilady.png"
import img_6 from "../images/pemalex_logo_clientes_fille.png"
import img_7 from "../images/pemalex_logo_clientes_delamore.png"
import img_8 from "../images/pemalex_logo_clientes_sobiquinis.png"
import img_9 from "../images/pemalex_logo_clientes_santapassion.png"
import img_10 from "../images/pemalex_logo_clientes_thaysethamires.png"
import img_11 from "../images/pemalex_logo_clientes_joy.png"
import img_12 from "../images/pemalex_logo_clientes_iurycosta.png"
import img_13 from "../images/pemalex_logo_clientes_soleagua.png"
import img_14 from "../images/pemalex_logo_clientes_leli.png"

export default function About() {
  return (
    <React.Fragment>
        <Navbar></Navbar>
        <Container>
            <div className="container">
                <div className="row pt-4 pb-5">
                    <div className="col-md-7">
                        <div className={aboutStyles.header}>
                            <h1>Pemalex é uma indústria que há 49 anos oferece entretelas, malhas, e tecidos de qualidade para todo o Brasil, orgulhosamente <em>feitos no Ceará</em>.</h1>
                        </div>
                        <div className="pt-3">
                            <h3>Atendemos empresas de moda feminina e masculina, moda íntima, moda fitness, moda infantil.</h3>
                        </div>
                    </div>
                    <div className="col-md-5 pl-md-5">
                        <img data-sal="slide-left" data-sal-delay="200" data-sal-easing="ease-out-circ" data-sal-duration="1900" src={img_1} alt="Fabrica" className={aboutStyles.vertical} />
                    </div>
                </div>
            </div>
        </Container>
        <div className={aboutStyles.wrapper}>
            <div className={aboutStyles.left}></div>
            <div className={aboutStyles.right}>
                <div className={aboutStyles.inner}>
                    <span data-sal="slide-left" data-sal-delay="200" data-sal-easing="ease-in-out-sine" data-sal-duration="1900">Nossa</span>
                    <p>
                    A empresa nasceu com a elaboração de uma máquina de fusionar entretelas em São Paulo, e em 1974 veio a se estabelecer em Maracanaú, Ceará. Fundada por Uok Kiu Kang, que imigrou da Coréia do Sul à São Paulo nos anos 60, hoje a Pemalex é administrada pela segunda geração da família.
                    </p>
                    <span data-sal="slide-left" data-sal-delay="200" data-sal-easing="ease-in-out-sine" data-sal-duration="1900">história</span>
                </div>
            </div>
        </div>
        <Container>
            <div className={aboutStyles.description}>
                <h3>Por muitos anos, a Pemalex foi especialista em entretelas e colarinhos, sendo a <em>maior fornecedora de entretelas na América Latina</em>, toda com produção local.</h3>
                <h3>Em 2008, introduzimos novos produtos fabricando malhas em poliamida, com foco no polo de moda íntima do Ceará. Acreditamos que manter a logística local é de benefício ao cliente, além da redução no impacto ambiental.</h3>
                <h3>Em 2015, lançamos a estamparia <a href="https://www.instagram.com/amaredesign/">Amaré Design</a>, que funciona como um ateliê, onde os tecidos são impressos em demanda. Sustentabilidade e incentivo ao mercado local também está nas raízes da estamparia. Tecidos estampados em demanda elimina o desperdício e dá oportunidade para as marcas menores crescerem com estampas que fazem parte de seu DNA.</h3>
                <h3>Atualmente nós fabricamos: malhas em poliamida, algodão, viscose, e poliéster, tecidos estampados em diversas composições.</h3>
            </div>
            <h1><Link to="/product/" className={aboutStyles.link}>Saiba mais sobre os nossos produtos&#xFEFF; →</Link></h1>
        </Container>
        <Container>
            <h1>Clientes</h1>
            <div className={aboutStyles.description}>
                <div className={aboutStyles.thirds} data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease-out-circ" data-sal-duration="1900">
                    <img alt="Logo Liebe" src={img_3}/>
                    <img alt="Logo Borogodo Brasil" src={img_4}/>
                    <img alt="Logo Dilady" src={img_5}/>
                    <img alt="Logo Fille" src={img_6}/>
                </div>
                <div className={aboutStyles.thirds} data-sal="slide-left" data-sal-delay="400" data-sal-easing="ease-out-circ" data-sal-duration="1900">
                    <img alt="Logo DelAmore" src={img_7}/>
                    <img alt="Logo Só Biquinis" src={img_8}/>
                    <img alt="Logo Santa Passion" src={img_9}/>
                    <img alt="Logo Thays e Thamires" src={img_10}/>
                </div>
                <div className={aboutStyles.thirds} data-sal="slide-left" data-sal-delay="500" data-sal-easing="ease-out-circ" data-sal-duration="1900">
                    <img alt="Logo Joy" src={img_11}/>
                    <img alt="Logo Iury Costa" src={img_12}/>
                    <img alt="Logo Sol & Agua" src={img_13}/>
                    <img alt="Logo Leli" src={img_14}/>
                </div>
            </div>
            <ContactLink></ContactLink>
        </Container>
        <div className={aboutStyles.parallax}></div>
        <Footer />
        <Copyright />
    </React.Fragment>
  )
}